import React, { useState,useEffect } from 'react';
import './App.css';
import axios from 'axios';
import Chatbot from './Chatbot';
import { BaseUrl } from './Constants/Constant';
import Lottie from 'react-lottie-player';
import micAnimation from './lottie/Mic-animation.json'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorPage from './ErrorPage/ErrorPage';

function App() {
  const [open, setOpen] = useState(false);
  const [UserId, setUserId] = useState("")
  const [ProfileIcon, setProfileIcon] = useState('');
  const [ errPage, setErrPage ] = useState(false);
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  const botID = BotID[0]

  useEffect(() => {
    let userIdURL = `${BaseUrl}/get_userid_from_botid`

    //Getting user ID from bot ID for UAT

    // let userIdURL = `https://api-uat.precium.ai/get_user_id?bot_id=${botID}`

   let body = {
    "bot_id": botID
  }
  axios
  .post(userIdURL, body)
  .then((res) => {

    console.log(res,"ress")
    setUserId(res.data.user_id)
  })
  .catch(( err ) => {
    console.log(err.message)
  })
}, [])

console.log(url.split('/'),'splittedUrl')


useEffect(() => {

  let splittedUrl = url.split('/');

  if( splittedUrl[3] != "" ){
    setErrPage(false);
  } else{
    setErrPage(true);
  }

},[])


// Parent React App
// const handleIframeMessage = (event) => {
//   // Make sure the message is coming from the expected iframe URL to avoid security risks
//   if (event.origin === 'http://127.0.0.1:5500') {
//     // Do something with the received message data
//     const isIframeOpen = event.data === 'iframe_open';
//     console.log(isIframeOpen,"openIframe")

//     // Now you have the information if the iframe is open or not (isIframeOpen)
//   }
// };

// useEffect(() => {
//   // Add event listener to listen for messages from the iframe
//   window.addEventListener('message', handleIframeMessage);
//   // Clean up the event listener when the component unmounts
//   return () => {
//     window.removeEventListener('message', handleIframeMessage);
//   };
// }, []);
// // When the iframe is open (you can trigger this wherever you determine that the iframe is open)
// window.parent.postMessage('iframe_open', 'http://localhost:9008');



  const handleToggleChatbot = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {

    if(botID === '88dc9af4-8ba0-4d07-9764-5d872d1f5fa4'){
      setOpen(false)
    }else{
      setOpen(true)
    }
    let dynamicURL = `${BaseUrl}/display_bot_details`

    // For UAT deployment changing dev url to UAT url only in this API

    // let dynamicURL = `https://api-uat.precium.ai/bot_full_details/`

    let body = {
      "bot_id": botID
    }

    axios
      .post(dynamicURL, body)
      .then((res) => {
        console.log(res, "res")

        if (res.data.chat_icon != '') {
          setProfileIcon(res.data.chat_icon)
        } else{
          setProfileIcon('/Logo/P-Logo.png')
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  return (
    <div className="app">
      {
        errPage ?
        <>
        <div className='errorPageDiv'>

          <ErrorPage />
        </div>
        </>
        :
        <>
        
        {!open ? (
          <>
          <img
            className="BotButton"
            onClick={handleToggleChatbot}
            src={ProfileIcon}
            alt="Chatbot"
          />
  
          {/* <Lottie
          loop={true}
          style={{ width: '100px', height: '100px' }}
          animationData={micAnimation}
          play
          direction="1"
          speed="1"
          >
          </Lottie> */}
  
          </>
          
          ) :
          (
  
            <Chatbot userId={UserId} onClose={handleToggleChatbot} />
          )}
        </>
      }
      
    </div>
    
  );
}

export default App;
