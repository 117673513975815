import React from 'react'
import './ErrorPage.css'

const ErrorPage = () => {
  return (
    <div>
        <div className="scene">
  <div className="text">404</div>
  <div className="text">Page Not Found!</div>
  <br /><br />
  {/* <div className="text">DO NOT TOUCH</div> */}
  <div className="sheep">
    <span className="top">
      <div className="body" />
      <div className="head">
        <div className="eye one" />
        <div className="eye two" />
        <div className="ear one" />
        <div className="ear two" />
      </div>
    </span>
    <div className="legs">
      <div className="leg" />
      <div className="leg" />
      <div className="leg" />
      <div className="leg" />
    </div>
  </div>
</div>

    </div>
  )
}

export default ErrorPage